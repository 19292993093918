<template>
    <div class="not-found">
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <app-card
                        :fullBlock="true"
                        colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-card height="90vh" color="transparent" flat>
                        <v-img src="/static/img/appic/notfound.jpg">
                            <v-card-text class="justify-space-between">
                                <v-card
                                        width="400px"
                                        color="rgba(255, 255, 255, 0.8)"
                                >
                                    <v-card-text>
                                        <div class="display-2 mt-0 ml-5 text--darken-2 green--text">Resource not found.</div>
                                        <div class="lighten-5 ml-5 text--darken-2 green--text">
                                            You seem to be lost in the APPiC forest.
                                        </div>
                                        <div class="paragraph-text mt-2 ml-5 text--darken-2 green--text">
                                            <br />Try repeating your action or go to another page or contact the IT Dept for support.
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </v-img>
                    </v-card>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped>

</style>